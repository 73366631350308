
import { Reservation, State } from '@/types'
import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import UsedDyestuffs from '@/components/UsedDyestuffs.vue'
import OperationHistoryTable from '@/components/Reservation/OperationHistoryTable.vue'
import WebSocketListener from '@/misc/websocket-listener'

@Component({ components: { UsedDyestuffs, OperationHistoryTable } })
export default class PreviousColorView extends Vue {
  @Prop({ type: String, required: true }) readonly shopId!: string

  reserves: Reservation[] = []
  isLoading = true
  dialog = {
    visible: false,
    customer: null as null | string,
  }
  filterTime = true
  now = moment()
  _ws: WebSocketListener | null = null

  get today() {
    return this.now.format('YYYY-MM-DD')
  }

  get items() {
    if (!this.filterTime) return this.reserves

    const now = this.now
    return this.reserves.filter(
      r => Math.abs(moment(r.start).diff(now, 'minute')) <= 60
    )
  }

  get headers() {
    return [
      {
        text: '予約時間',
        value: 'time',
      },
      {
        text: '氏名(お客様ID)',
        value: 'name',
      },
      {
        text: '前回カラー（根本）',
        value: 'dyestuffRoot',
      },
      {
        text: '前回カラー（毛先）',
        value: 'dyestuffEnd',
      },
      {
        text: '色の要望',
        value: 'requestColorText',
      },
      {
        text: '前回来店日',
        value: 'previousReservedDate',
      },
    ]
  }

  created() {
    this.fetchData()
  }

  mounted() {
    this._ws = new WebSocketListener(
      `${process.env.VUE_APP_WS_ENDPOINT}timeline/${this.shopId}/`
    )
      .on('open', () => {
        this.fetchData()
      })
      .on('data', () => {
        console.log('?ws update')
        this.fetchData()
      })
  }

  beforeDestroy() {
    if (this._ws) {
      this._ws.destroy()
      this._ws = null
    }
  }

  deltaDays(date: string, prevDate: null | string) {
    if (!prevDate) return null
    return moment(date).diff(prevDate, 'days')
  }

  async fetchData() {
    let reserves = (await this.$api.previousOperations(
      this.shopId,
      this.today
    )) as Reservation[]
    this.isLoading = false
    reserves = reserves.filter(x => x.state !== State.Cancel)
    reserves = reserves.filter(x => x.state !== State.NoShow)
    reserves = reserves.filter(x => !x.isRemoved)
    reserves.sort((a, b) => a.time.localeCompare(b.time))
    this.reserves = reserves
  }

  onClickRow(row: Reservation) {
    this.dialog.customer = row.customer
    if (row.customer) {
      this.dialog.visible = true
    }
  }
}
